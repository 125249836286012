import initialState from '../store/initState/loader';

export default (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case 'LOADER_SET':      
      newState = {...state};
      newState[action.fieldName] = action.data;
      return newState;
    default:
      return state;
  }
};
