import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const validateEmail = (email) => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const elementToPDF = (element, filename, options = {}) => {
  return new Promise(async (resolve) => {
    window.scrollTo(0, 0);
    const el = document.querySelector(element);
  	const canvas = await html2canvas(el, {
      ...options,
    });
    canvas.getContext('2d');

    let HTML_Width = el.offsetWidth;
  	let HTML_Height = el.offsetHeight;
    let PDF_Width = HTML_Width;
    let PDF_Height = PDF_Width * 1.5;
    let totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;

    let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
    let imgData = canvas.toDataURL('image/jpeg');

    pdf.addImage(imgData, 'JPEG', 0, 0, HTML_Width, HTML_Height);

    for (let i = 1; i <= totalPDFPages; i++) {
  		pdf.addPage(PDF_Width, PDF_Height);
  		pdf.addImage(imgData, 'JPEG', 0, -(PDF_Height*i), HTML_Width, HTML_Height);
  	}

    pdf.save(filename);
    resolve();
  });
}
