import React, { Component } from 'react';
import { Switch, Route, HashRouter as Router, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

/* import stylesheets */
import 'bootstrap/dist/css/bootstrap.min.css';
import '../stylesheets/index.scss';

/* Actions */
import * as loader from '../actions/loader';
import * as modal from '../actions/modal';
import * as preload from '../actions/preload';
import * as login from '../actions/login';
import * as home from '../actions/home';
import * as survey from '../actions/survey';
import * as graph from '../actions/graph';

/* Containers & Components */
import Loader from '../components/Loader';
import Modal from '../components/Modal';
import Authentication from '../components/Authentication';
import Login from './Login';
import Home from './Home';
import Survey from './Survey';
import Graph from './Graph';
import FullGraph from './Graph/full';

const mapStateToProps = state => ({ ...state, });

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  history: ownProps.history,
  ...preload,
  ...loader,
  ...modal,
  ...login,
  ...home,
  ...survey,
  ...graph,
}, dispatch);

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}
const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, rest);
    }}/>
  );
}

class App extends Component {
  render(){
    return (
      <Router>
        <div id="page">
          <Loader {...this.props.loader} />
          <Modal {...this.props.modal} setModal={this.props.setModal} />
          <Authentication
            data={this.props.login.authentication}
            history={this.props.history}
            params={this.props.computedMatch ? this.props.computedMatch.params :  {}}
          />
          <Switch>
            <PropsRoute exact path="/login/:token" component={Login} { ...this.props } />
            <PropsRoute exact path="/login" component={Login} { ...this.props } />
            <PropsRoute exact path="/" component={Home} { ...this.props } />
            <PropsRoute exact path="/survey/:id/:type" component={Survey} { ...this.props } />
            <PropsRoute exact path="/survey/:id/:type/:survey_number" component={Survey} { ...this.props } />
            <PropsRoute exact path="/graph/:id" component={Graph} { ...this.props } />
            <PropsRoute exact path="/graph/:id/survey_number/:survey_number" component={Graph} { ...this.props } />
            <PropsRoute exact path="/graph/:id/full" component={FullGraph} { ...this.props } />
            <PropsRoute exact path="/graph/:id/survey_number/:survey_number/full" component={FullGraph} { ...this.props } />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App));
