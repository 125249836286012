import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import loader from '../reducers/loader';
import modal from '../reducers/modal';
import preload from '../reducers/preload';
import login from '../reducers/login';
import home from '../reducers/home';
import survey from '../reducers/survey';
import graph from '../reducers/graph';

export default (history) => combineReducers({
  router: connectRouter(history),
  loader,
  modal,
  preload,
  login,
  home,
  survey,
  graph,
});
