import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  Modal,
} from 'react-bootstrap';
import { validateEmail } from '../../utils';
import '../../stylesheets/login.scss';

class Login extends Component {
  constructor(props){
    super(props);

    this.state = {
      email: '',
      password: '',
      resetEmail: '',
      isForgotPassword: false,
    }
    this.onLogin = this.onLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  async componentDidMount() {
    console.log('Login did mount');
  }

  handleChange = event => {
    const target = event.currentTarget;
    this.setState((state) => ({
      [target.name]: target.value,
    }));
  };

  onLogin = () => {
    if(!this.state.email || !this.state.password){
      this.props.setModal({isVisible: true, title: 'Warning', msg: 'Email and Password mandatory!'});
    }else if(this.state.email && !validateEmail(this.state.email)){
        this.props.setModal({isVisible: true, title: 'Warning', msg: 'Please, insert a valid email!'});
    }else{
      this.props.setAuthentication({
        username: this.state.email,
        password: this.state.password,
      }).then(() => {
        this.props.setModal({
          isVisible: true,
          title: 'You are welcome!',
          size: 'xl',
          component: this.WelcomeContainer
        });
      });
    }

  }

  onOpenForgotPassword = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ isForgotPassword: !this.state.isForgotPassword })
  }

  onResetPassword = () => {
    this.props.setLoader(true);
    this.props.passwordEmail(this.state.resetEmail)
      .then(res => {
        this.props.setLoader(false);
        this.props.setModal({isVisible: true, title: 'Reset Password', msg: res.data.msg});
      })
  }

  onPasswordConfirm = () => {
    this.props.setLoader(true);
    this.props.passwordReset({
      email: this.state.email,
      token: this.props.computedMatch.params.token,
      password: this.state.password,
      password_confirmation: this.state.passwordConfirm,
    })
      .then(res => {
        this.props.setLoader(false);
        this.props.setModal({isVisible: true, title: 'Reset Password', msg: res.data.msg});
        this.props.history.push('/login');
        this.setState({
          isForgotPassword: false,
        });
      })
  }

  ResetContainer = () => {
    return (
      <Form>
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control value={this.state.email} type="email" name="email" placeholder="Enter email" onChange={this.handleChange} />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control value={this.state.password} type="password" name="password" placeholder="Password" onChange={this.handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password Confirmation </Form.Label>
            <Form.Control value={this.state.passwordConfirm} type="password" name="passwordConfirm" placeholder="Password Confirmation" onChange={this.handleChange} />
          </Form.Group>
          <Button variant="primary" type="button" block onClick={this.onPasswordConfirm} style={{"marginTop": "1rem"}}>Reset Password</Button>
        </Form>
    );
  }

  LoginContainer = () => {
    return (
        <Row className="justify-content-center">
          <Col md="6">
            <Card>
              <Card.Body>
                <Card.Title style={{textAlign: "center", color: "#0b4ca2"}}>Welcome to finMED Tool for Business Development</Card.Title>
                <Card.Text><img src="img/LOGO_ERDF_finMED-En.jpg" style={{width: '100%'}} alt="logo-finmed" /></Card.Text>
                {
                  !this.props.computedMatch.params.token
                  ? <Form>
                      <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control value={this.state.email} type="email" name="email" placeholder="Enter email" onChange={this.handleChange} />
                        <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control value={this.state.password} type="password" name="password" placeholder="Password" onChange={this.handleChange} />
                      </Form.Group>
                      <Card.Link href="#" onClick={this.onOpenForgotPassword}>Forgot Password</Card.Link>
                      { this.state.isForgotPassword &&
                          <Form.Group>
                            <Form.Control value={this.state.resetEmail} type="text" name="resetEmail" placeholder="Please, tap your e-mail..." onChange={this.handleChange} />
                          </Form.Group>
                      }
                      { this.state.isForgotPassword
                          ? <Button variant="primary" type="button" block onClick={this.onResetPassword} style={{"marginTop": "1rem"}}>Reset Password</Button>
                          : <Button variant="success" type="button" block onClick={this.onLogin} style={{"marginTop": "1rem"}}>Login</Button>
                      }

                    </Form>
                    : this.ResetContainer()
                }

              </Card.Body>
            </Card>
          </Col>
        </Row>
    )
  }

  WelcomeContainer = () => {
    return (
      <div>
        <h5>
          THIS TOOL IS FOR CLUSTERS/BSOs SUPPORTING SME (SMALL-MEDIUM ENTERPRISE) OF THE GREEN GROWTH SECTOR
        </h5>
        <b>The tool is composed by 4 parts:</b>
        <ol>
          <li>an intro section with some necessary basic questions about the enterprise and the respondent</li>
          <li>a questionnaire</li>
          <li>a final report, with the results of the analysis developed by the tool’s logic engine, according to the answers of the respondent (all expressed by charts)</li>
          <li>a decision support by highlights in order to suggest for what kind of financial instrument the SME looks to be ready, almost ready, not yet ready to propose its financing needs for help its innovation/R&amp;amp;D plan and business development.</li>
        </ol>
        <b>
          In order to work properly, the tool needs to get data and information about the SME that can be provided mainly by a respondent with a good knowledge about the enterprise.
        </b>
        <br /><br />
        The question set has a wide range of knowledge-based, competency-based and expertise-based questions/answers but it’s accessible for who has a typical work experience with the management of the enterprise and updated information about it. Therefore, a consultant or advisor of the SME can be a suitable respondent too.
        <br /><br />
        <b>
          Such data and information will be got by the respondent answering the questionnaire composed by 7 chapters/sections:
        </b>
        <ol>
          <li>R&D and innovation</li>
          <li>Communication and positioning</li>
          <li>Adequacy to the green growth</li>
          <li>Financial assessment</li>
          <li>Market and internationalisation</li>
          <li>Management and human capital</li>
          <li>Enabling asset</li>
        </ol>
        <b>
          The amount of the questions is 60
        </b>
        <br /><br />
        Estimated time to complete the filling of the intro section and all the questionnaire: approximately 60 minutes.
        <br /><br />
        <b>
          At the end, the respondent will get:
        </b>
        <ol>
          <li>
            <b>a report of the comparison made by a gap-analysis</b>
            <ul>
              <li>between the SME and a benchmark which is an average expression of the result of other SMEs that had success in order to get financing resources</li>
              <li>between the readiness of the SME about almost all the kinds of financial instruments and the benchmark which is an average expression of the result of other SMEs that had success in order to get positive access to the specific single financial instrument</li>
            </ul>
          </li>
          <li>
            <b>a decision support by comments (highlights) that will suggest </b>
            <ul>
              <li>how the SME should work on the 7 business topics, described above, plus another one, "overall business" topic</li>
              <li>for what kind of financial instrument/s the SME looks ready/almost ready/not yet ready to get access.</li>
            </ul>
          </li>
        </ol>
      </div>
    );
  }

  render(){
    return (
      <Container className="container-marginTop" id="login">
        <this.LoginContainer />
      </Container>
    );
  }

}

Login.propTypes = {

};

export default Login;
