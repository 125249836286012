import React from 'react';

const Loader = (props) => {
  return props.isVisible && (
    <div className="loader">
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  );
}

export default Loader;
