import initialState from '../store/initState/loader';

export default (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case 'MODAL_SET':
      newState = {
        ...state,
        size: action.data.size,
        isVisible: action.data.isVisible || false,
        title: action.data.title || '',
        msg: action.data.msg,
        component: action.data.component,
      };
      return newState;
    default:
      return state;
  }
};
