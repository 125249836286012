import { createHashHistory } from "history";
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createRootReducer from '../reducers';
import initState from './initState';

export const history = createHashHistory({
  hashType: 'slash',
});

// redux dev tools
const composeEnhancers = composeWithDevTools({});

export default function configureStore() {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    initState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunk,
        // ... other middlewares ...
      ),
    ),
  )

  return store;
}
