import request, { catchHandler, generateQueryString, mock} from '../utils/request';
import api from '../config/api';
const env = process.env.NODE_ENV;

export const setUsersEnterprises = (params = {}) => {
  return dispatch => {
    request.get(`${api[env].baseURL}${api[env].apiVersion}${api.services.usersEnterprises}${generateQueryString(params)}`)
      .then(response => {
        dispatch({
          type: 'HOME_SET_USERS_ENTERPRISES',
          data: {
            ...response.data,
          },
        });
      })
      .catch((err) => catchHandler(err, dispatch))
  }
};

export const getUserEnterprise = (id) => {
  return dispatch => {
    dispatch({
      type: 'LOADER_SET',
      data: true,
      fieldName: 'isVisible',
    });
    return new Promise(resolve => {
      request.get(`${api[env].baseURL}${api[env].apiVersion}${api.services.usersEnterprises}/${id}`)
        .then((response) => {
          dispatch({
            type: 'LOADER_SET',
            data: false,
            fieldName: 'isVisible',
          });

          resolve(response);
        })
        .catch((err) => catchHandler(err, dispatch))
    })
  }
};

export const createUsersEnterprises = (body = {}, id) => {
  return dispatch => {
    return new Promise((resolve) => {
      dispatch({
        type: 'LOADER_SET',
        data: true,
        fieldName: 'isVisible',
      });

      request.post(`${api[env].baseURL}${api[env].apiVersion}${api.services.usersEnterprises}${id ? `/${id}` : '' }`, body)
        .then(response => {
          dispatch({
            type: 'LOADER_SET',
            data: false,
            fieldName: 'isVisible',
          });

          resolve(response);
        })
        .catch((err) => catchHandler(err, dispatch))
    })
  }
};

export const getHistoryEnterprise = (enterprise_id, dateFrom, dateTo) => {
  return dispatch => {
    dispatch({
      type: 'LOADER_SET',
      data: true,
      fieldName: 'isVisible',
    });
    return new Promise((resolve, reject) => {
      request.get(`${api[env].baseURL}${api[env].apiVersion}${api.services.historyEnterprise}/${dateFrom}/${dateTo}?enterprise_id=${enterprise_id}`)
        .then((response) => {
          dispatch({
            type: 'LOADER_SET',
            data: false,
            fieldName: 'isVisible',
          });

          resolve(response.data);
        })
        .catch((err) => {
          catchHandler(err, dispatch)
          reject(err);
        })
    })
  }
};
