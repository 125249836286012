import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import {
  Container,
  Row,
  Col,
  Navbar,
  Dropdown,
  Button,
  Card,
  Modal,
  Form,
  Table,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import '../../stylesheets/home.scss';

const LIMIT_HISTORY = 50;

class Home extends Component {
  constructor(props){
    super(props);

    this.state = {
      selectedCompanyId: null,
      modalManageCompany: {
        isVisible: false,
        type: '',
        data: {},
      },
      history: {
        startDate: null,
        endDate: null,
        data: [],
      },
    }

    this.onSelectDropDownItem =  this.onSelectDropDownItem.bind(this);
  }

  componentDidMount() {
    console.log('Home did mount');

    // init setting, call users enterprises when data is null
    if(this.props.login.authentication.user_id && !this.props.home.usersEnterprises)
      this.props.setUsersEnterprises();
  }

  onSelectDropDownItem = (eventKey, event) => {
    this.props.getHistoryEnterprise(parseInt(eventKey, 10), 0, 0)
      .then(response => {
        this.setState(state => ({
          selectedCompanyId: parseInt(eventKey, 10),
          history: {
            ...this.state.history,
            startDate: null,
            endDate: null,
            data: response.data,
          }
        }), () => this.props.resetGraph());
      })
      .catch(err => {
        this.setState(state => ({
          selectedCompanyId: parseInt(eventKey, 10),
          history: {
            ...this.state.history,
            startDate: null,
            endDate: null,
            data: [],
          }
        }), () => this.props.resetGraph());
      })
  }

  TopBarContainer = () => {
    return (
      <Navbar bg="light" variant="primary" fixed="top" id="topBar">
        <Navbar.Brand href="#/">
          finMED Tool
        </Navbar.Brand>
      </Navbar>
    )
  }

  handleChangeCompany = event => {
    const target = event.currentTarget;
    const addUniqArray = (target, state) => {
      if(target.type === 'checkbox'){
        let surveyState =  state[target.name] || [];
        // if not exist item then add it else remove from state
        return !surveyState.find(el => el === target.id)
          ? [...surveyState, target.id]
          : surveyState.filter(el => el !== target.id)
      }else return [target.id];
    };

    this.setState((state) => ({
      ...state,
      modalManageCompany: {
        ...state.modalManageCompany,
        data: {
          ...state.modalManageCompany.data,
          [target.name]: target.type === 'text' || target.type === 'textarea' || target.type === 'email'
            ? target.value
            : addUniqArray(target, state.modalManageCompany.data),
        }
      }
    }));
  };

  onChangeDateHistory = (date, field) => {
    this.setState({
      history: {
        ...this.state.history,
        [field]: date,
      },
    })
  }

  onSearchHistoryEnterprise = () => {
    let startDate = moment(this.state.history.startDate).format("YYYY-MM-DD");
    let endDate = moment(this.state.history.endDate).format("YYYY-MM-DD");
    this.props.getHistoryEnterprise(this.state.selectedCompanyId, startDate, endDate)
      .then(response => {
        if(response.count > LIMIT_HISTORY)
          this.props.setModal({isVisible: true, title: 'Warning', size: 'ml', msg: `Please reduce the time window. The results exceed the maximum number of ${LIMIT_HISTORY} records.`});

        this.setState({
          history: {
            ...this.state.history,
            data: response.data,
          }
        });
      });
  }

  onManageCompany = async (type) => {
    let data = {
      size_number_competitors_bigger: '',
      size_number_competitors_same: '',
      size_number_competitors_smaller: '',
      size_number_competitors_no_considerable: '',
    };
    if(type === 'manage'){
        const enterpriseSelected = await this.props.getUserEnterprise(this.state.selectedCompanyId);
        let size_number_competitors = {};
        try{ size_number_competitors = JSON.parse(enterpriseSelected.data.data[0].size_number_competitors); }catch{ size_number_competitors = {}; }
        data = {
          ...enterpriseSelected.data.data[0],
          ...size_number_competitors,
        };
    }

    Object.keys(data)
      .forEach((elKey) => {
        try{
          data[elKey] = JSON.parse(data[elKey]);
        }catch(e){
          data[elKey] = data[elKey];
        }
      });

    this.setState(state => ({
      modalManageCompany: {
        ...state.modalManageCompany,
        isVisible: true,
        data,
        type,
      },
    }));
  }

  BodyGeneralEnterpriseInformation = () => {
    return (
      <Form>
        <Form.Group>
          <Form.Label>Enterprise Name</Form.Label>
          <Form.Control type="text" name="company_name" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.company_name || ''} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Enterprise e-mail</Form.Label>
          <Form.Control type="email" placeholder="A valid company e-mail address" name="company_email" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.company_email || ''} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Respondent</Form.Label>
          <Form.Control type="text" placeholder="Surname and Name" name="respondent" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.respondent || ''} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Respondent's email</Form.Label>
          <Form.Control type="email" placeholder="A valid company e-mail address" name="respondent_email" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.respondent_email || ''} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Respondent's role in the Enterprise</Form.Label>
          <Form.Control type="text" name="respondent_role" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.respondent_role || ''} />
        </Form.Group>

        <Form.Group>
          <Form.Label>Phone number</Form.Label>
          <Form.Control type="text" name="phone_number" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.phone_number || ''} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Country</Form.Label>
          <Form.Control type="text" placeholder="Ex. Italy" name="country" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.country || ''} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Region</Form.Label>
          <Form.Control type="text" placeholder="Ex. Piemonte" name="region" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.region || ''} />
        </Form.Group>
        <Form.Group>
          <Form.Label>VAT number</Form.Label>
          <Form.Control type="text" name="vat_number" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.vat_number || ''} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Street</Form.Label>
          <Form.Control type="text" name="street" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.street || ''} />
        </Form.Group>
        <Form.Group>
          <Form.Label>City</Form.Label>
          <Form.Control type="text" name="city" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.city || ''} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Postcode</Form.Label>
          <Form.Control type="text" name="postcode" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.postcode || ''} />
        </Form.Group>
      </Form>
    );
  }

  BodyCompanyOverview = () => {
    return (
        <Form>
          <Form.Group>
            <Form.Label>Vision</Form.Label>
            <Form.Control type="text" name="vision" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.vision || ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Mission</Form.Label>
            <Form.Control type="text" name="mission" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.mission || ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>If you like to answer... A short enterprise description and storytelling</Form.Label>
            <Form.Control as="textarea" rows="3" name="description_storytelling" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.description_storytelling || ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>If you like to answer... A short description of enterprise outlook and goals in the green growth sector</Form.Label>
            <Form.Control as="textarea" rows="3" name="outlook_goals" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.outlook_goals || ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>If you like to answer... A short description of enterprise innovation goals/results in green growth sector</Form.Label>
            <Form.Control as="textarea" rows="3" name="innovation_goals_results" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.innovation_goals_results || ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Turnover in €</Form.Label>
            <Form.Control type="text" name="turnover" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.turnover || ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Your market is:</Form.Label><br/>
            <Form.Check inline label="B2B - Business to Business" type="checkbox" id="B2B - Business to Business" name="market" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.market) && this.state.modalManageCompany.data.market.find(f => f === 'B2B - Business to Business') !== undefined} />
            <Form.Check inline label="B2C - Business to Consumer" type="checkbox" id="B2C - Business to Consumer" name="market" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.market) && this.state.modalManageCompany.data.market.find(f => f === 'B2C - Business to Consumer') !== undefined} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Year of establishment</Form.Label>
            <Form.Control type="text" name="year_establishment" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.year_establishment || ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Year of the last enterprise transformation. E.g. from partnership to corporate or any kind of M&A operations involving the enterprise (if applicable)</Form.Label>
            <Form.Control type="text" name="year_last_transformation" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.year_last_transformation || ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Year of business refocusing or transformation (if applicable)</Form.Label>
            <Form.Control type="text" name="year_refocusing_transformation" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.year_refocusing_transformation || ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>NACE classification code</Form.Label>
            <Form.Control type="text" name="nace_classification_code" onChange={this.handleChangeCompany} value={this.state.modalManageCompany.data.nace_classification_code || ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Business area</Form.Label><br/>
            <Form.Check label="Research and development" type="checkbox" id="Research and development" name="business_area" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.business_area) && this.state.modalManageCompany.data.business_area.find(f => f === 'Research and development') !== undefined} />
            <Form.Check label="Manufacturing" type="checkbox" id="Manufacturing" name="business_area" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.business_area) && this.state.modalManageCompany.data.business_area.find(f => f === 'Manufacturing') !== undefined} />
            <Form.Check label="Waste Management" type="checkbox" id="Waste Management" name="business_area" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.business_area) && this.state.modalManageCompany.data.business_area.find(f => f === 'Waste Management') !== undefined} />
            <Form.Check label="Agricultural, livestock and food economics" type="checkbox" id="Agricultural, livestock and food economics" name="business_area" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.business_area) && this.state.modalManageCompany.data.business_area.find(f => f === 'Agricultural, livestock and food economics') !== undefined} />
            <Form.Check label="Logistic and distribution" type="checkbox" id="Logistic and distribution" name="business_area" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.business_area) && this.state.modalManageCompany.data.business_area.find(f => f === 'Logistic and distribution') !== undefined} />
            <Form.Check label="Business services" type="checkbox" id="Business services" name="business_area" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.business_area) && this.state.modalManageCompany.data.business_area.find(f => f === 'Business services') !== undefined} />
            <Form.Check label="Transportation" type="checkbox" id="Transportation" name="business_area" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.business_area) && this.state.modalManageCompany.data.business_area.find(f => f === 'Transportation') !== undefined} />
            <Form.Check label="Energy" type="checkbox" id="Energy" name="business_area" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.business_area) && this.state.modalManageCompany.data.business_area.find(f => f === 'Energy') !== undefined} />
            <Form.Check label="Product design/develepoment" type="checkbox" id="Product design/develepoment" name="business_area" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.business_area) && this.state.modalManageCompany.data.business_area.find(f => f === 'Product design/develepoment') !== undefined} />
            <Form.Check label="ICT" type="checkbox" id="ICT" name="business_area" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.business_area) && this.state.modalManageCompany.data.business_area.find(f => f === 'ICT') !== undefined} />
            <Form.Check label="Other" type="checkbox" id="Other" name="business_area" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.business_area) && this.state.modalManageCompany.data.business_area.find(f => f === 'Other') !== undefined} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Number of Human resources</Form.Label><br/>
            <Form.Check label="0-5" type="radio" id="0-5" name="number_human_resources" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.number_human_resources) && this.state.modalManageCompany.data.number_human_resources.find(f => f === '0-5') !== undefined} />
            <Form.Check label="6-10" type="radio" id="6-10" name="number_human_resources" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.number_human_resources) && this.state.modalManageCompany.data.number_human_resources.find(f => f === '6-10') !== undefined} />
            <Form.Check label="11-25" type="radio" id="11-25" name="number_human_resources" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.number_human_resources) && this.state.modalManageCompany.data.number_human_resources.find(f => f === '11-25') !== undefined} />
            <Form.Check label="26-50" type="radio" id="26-50" name="number_human_resources" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.number_human_resources) && this.state.modalManageCompany.data.number_human_resources.find(f => f === '26-50') !== undefined} />
            <Form.Check label="51-100" type="radio" id="51-100" name="number_human_resources" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.number_human_resources) && this.state.modalManageCompany.data.number_human_resources.find(f => f === '51-100') !== undefined} />
            <Form.Check label="101-250" type="radio" id="101-250" name="number_human_resources" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.number_human_resources) && this.state.modalManageCompany.data.number_human_resources.find(f => f === '101-250') !== undefined} />
            <Form.Check label="> 250" type="radio" id="> 250" name="number_human_resources" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.number_human_resources) && this.state.modalManageCompany.data.number_human_resources.find(f => f === '> 250') !== undefined} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Enterprise size category</Form.Label><br/>
            <Form.Check label="medium - sized (annual work unit < 250; annual turnover <= €50M or annual balance sheet <= €43M)" type="radio" id="medium - sized (annual work unit < 250; annual turnover <= €50M or annual balance sheet <= €43M)" name="enterprise_size_category" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data.enterprise_size_category) && this.state.modalManageCompany.data.enterprise_size_category.find(f => f === 'medium - sized (annual work unit < 250; annual turnover <= €50M or annual balance sheet <= €43M)') !== undefined} />
            <Form.Check label="small (annual work unit < 50; annual turnover < €10M or annual balance sheet <= €10M)" type="radio" id="small (annual work unit < 50; annual turnover < €10M or annual balance sheet <= €10M)" name="enterprise_size_category" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data.enterprise_size_category) && this.state.modalManageCompany.data.enterprise_size_category.find(f => f === 'small (annual work unit < 50; annual turnover < €10M or annual balance sheet <= €10M)') !== undefined} />
            <Form.Check label="micro (annual work unit < 10; annual turnover < €2M or annual balance sheet <= €2M)" type="radio" id="micro (annual work unit < 10; annual turnover < €2M or annual balance sheet <= €2M)" name="enterprise_size_category" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data.enterprise_size_category) && this.state.modalManageCompany.data.enterprise_size_category.find(f => f === 'micro (annual work unit < 10; annual turnover < €2M or annual balance sheet <= €2M)') !== undefined} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Legal Form</Form.Label><br/>
            <Form.Check label="Sole proprietorship" type="checkbox" id="Sole proprietorship" name="legal_form" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.legal_form) && this.state.modalManageCompany.data.legal_form.find(f => f === 'Sole proprietorship') !== undefined} />
            <Form.Check label="Partnership" type="checkbox" id="Partnership" name="legal_form" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.legal_form) && this.state.modalManageCompany.data.legal_form.find(f => f === 'Partnership') !== undefined} />
            <Form.Check label="Corporation" type="checkbox" id="Corporation" name="legal_form" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.legal_form) && this.state.modalManageCompany.data.legal_form.find(f => f === 'Corporation') !== undefined} />
            <Form.Check label="Cooperative" type="checkbox" id="Cooperative" name="legal_form" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.legal_form) && this.state.modalManageCompany.data.legal_form.find(f => f === 'Cooperative') !== undefined} />
            <Form.Check label="Other" type="checkbox" id="Other" name="legal_form" onChange={this.handleChangeCompany} checked={Array.isArray(this.state.modalManageCompany.data.legal_form) && this.state.modalManageCompany.data.legal_form.find(f => f === 'Other') !== undefined} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Life stage of the company and level consolidation reached by the enterprise</Form.Label><br/>
            <Form.Check label="Planning phase or established enterprise but not yet generating revenues" type="radio" id="Planning phase or established enterprise but not yet generating revenues" name="life_stage_company-level_consolidation" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data["life_stage_company-level_consolidation"]) && this.state.modalManageCompany.data["life_stage_company-level_consolidation"].find(f => f === 'Planning phase or established enterprise but not yet generating revenues') !== undefined} />
            <Form.Check label="Early stage start-up generating revenues" type="radio" id="Early stage start-up generating revenues" name="life_stage_company-level_consolidation" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data["life_stage_company-level_consolidation"]) && this.state.modalManageCompany.data["life_stage_company-level_consolidation"].find(f => f === 'Early stage start-up generating revenues') !== undefined} />
            <Form.Check label="Growth stage and development (going to break even)" type="radio" id="Growth stage and development (going to break even)" name="life_stage_company-level_consolidation" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data["life_stage_company-level_consolidation"]) && this.state.modalManageCompany.data["life_stage_company-level_consolidation"].find(f => f === 'Growth stage and development (going to break even)') !== undefined} />
            <Form.Check label="Full activity (enabled maximum business potentiality)" type="radio" id="Full activity (enabled maximum business potentiality)" name="life_stage_company-level_consolidation" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data["life_stage_company-level_consolidation"]) && this.state.modalManageCompany.data["life_stage_company-level_consolidation"].find(f => f === 'Full activity (enabled maximum business potentiality)') !== undefined} />
            <Form.Check label="Maturity and/or decline (mature business and decrease of development opportunities)" type="radio" id="Maturity and/or decline (mature business and decrease of development opportunities)" name="life_stage_company-level_consolidation" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data["life_stage_company-level_consolidation"]) && this.state.modalManageCompany.data["life_stage_company-level_consolidation"].find(f => f === 'Maturity and/or decline (mature business and decrease of development opportunities)') !== undefined} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Ownership</Form.Label><br/>
            <Form.Check label="1 (sole) owner" type="radio" id="1 (sole) owner" name="ownership" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data.ownership) && this.state.modalManageCompany.data.ownership.find(f => f === '1 (sole) owner') !== undefined}/>
            <Form.Check label="2-5 partners/shareholders" type="radio" id="2-5 partners/shareholders" name="ownership" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data.ownership) && this.state.modalManageCompany.data.ownership.find(f => f === '2-5 partners/shareholders') !== undefined} />
            <Form.Check label="6-10 partners/shareholders" type="radio" id="6-10 partners/shareholders" name="ownership" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data.ownership) && this.state.modalManageCompany.data.ownership.find(f => f === '6-10 partners/shareholders') !== undefined} />
            <Form.Check label="11-20 partners/shareholders" type="radio" id="11-20 partners/shareholders" name="ownership" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data.ownership) && this.state.modalManageCompany.data.ownership.find(f => f === '11-20 partners/shareholders') !== undefined} />
            <Form.Check label="> 21 partners/shareholders" type="radio" id="> 21 partners/shareholders" name="ownership" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data.ownership) && this.state.modalManageCompany.data.ownership.find(f => f === '> 21 partners/shareholders') !== undefined} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Experience partners/shareholders and sole owner</Form.Label><br/>
            <Form.Check label="At least one with experience in other enterprises of the same business area/sector" type="checkbox" id="At least one with experience in other enterprises of the same business area/sector" name="partners_shareholders-sole_owner" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data["partners_shareholders-sole_owner"]) && this.state.modalManageCompany.data["partners_shareholders-sole_owner"].find(f => f === 'At least one with experience in other enterprises of the same business area/sector') !== undefined} />
            <Form.Check label="At least one with experience in other enterprises of a different business area/sector" type="checkbox" id="At least one with experience in other enterprises of a different business area/sector" name="partners_shareholders-sole_owner" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data["partners_shareholders-sole_owner"]) && this.state.modalManageCompany.data["partners_shareholders-sole_owner"].find(f => f === 'At least one with experience in other enterprises of a different business area/sector') !== undefined} />
            <Form.Check label="None with business enterprise" type="checkbox" id="None with business enterprise" name="partners_shareholders-sole_owner" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data["partners_shareholders-sole_owner"]) && this.state.modalManageCompany.data["partners_shareholders-sole_owner"].find(f => f === 'None with business enterprise') !== undefined} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Size and number of competitors (please, indicate a number as reliable as possible)</Form.Label><br/>
            Bigger size competitors
            <Form.Control id="Bigger size competitors" name="size_number_competitors_bigger" onChange={this.handleChangeCompany} type="text" value={this.state.modalManageCompany.data.size_number_competitors_bigger} />
            Same size competitors
            <Form.Control id="Same size competitors" name="size_number_competitors_same" onChange={this.handleChangeCompany} type="text" value={this.state.modalManageCompany.data.size_number_competitors_same} />
            Smaller competitors
            <Form.Control id="Smaller competitors" name="size_number_competitors_smaller" onChange={this.handleChangeCompany} type="text" value={this.state.modalManageCompany.data.size_number_competitors_smaller} />
            Almost no considerable competitor
            <Form.Control id="Almost no considerable competitor" name="size_number_competitors_no_considerable" onChange={this.handleChangeCompany} type="text" value={this.state.modalManageCompany.data.size_number_competitors_no_considerable} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Trend of the sales volumes in the last 3 years or, if enterprise younger than 3 years old, in the last considerable period</Form.Label><br/>
            <Form.Check label="Strong growth" type="radio" id="Strong growth" name="trend_sales_volume" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data.trend_sales_volume) && this.state.modalManageCompany.data.trend_sales_volume.find(f => f === 'Strong growth') !== undefined} />
            <Form.Check label="Moderate growth" type="radio" id="Moderate growth" name="trend_sales_volume" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data.trend_sales_volume) && this.state.modalManageCompany.data.trend_sales_volume.find(f => f === 'Moderate growth') !== undefined} />
            <Form.Check label="Stable" type="radio" id="Stable" name="trend_sales_volume" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data.trend_sales_volume) && this.state.modalManageCompany.data.trend_sales_volume.find(f => f === 'Stable') !== undefined} />
            <Form.Check label="Moderate decrease" type="radio" id="Moderate decrease" name="trend_sales_volume" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data.trend_sales_volume) && this.state.modalManageCompany.data.trend_sales_volume.find(f => f === 'Moderate decrease') !== undefined} />
            <Form.Check label="Strong decrease" type="radio" id="Strong decrease" name="trend_sales_volume" onChange={this.handleChangeCompany}
              checked={Array.isArray(this.state.modalManageCompany.data.trend_sales_volume) && this.state.modalManageCompany.data.trend_sales_volume.find(f => f === 'Strong decrease') !== undefined} />
          </Form.Group>
        </Form>
    );
  }

  saveCompany = () => {
    let size_number_competitors = {};
    let body = { ...this.state.modalManageCompany.data };
    Object.keys(this.state.modalManageCompany.data)
      .forEach((elKey) => {
        if(elKey.indexOf("size_number_competitors_") !== -1){
          size_number_competitors[elKey] = body[elKey];
          delete body[elKey];
        }
      });

    body = { ...body, size_number_competitors, };

    Object.keys(body)
      .forEach((elKey) => {
        body[elKey] = typeof body[elKey] !== 'string'
          ? JSON.stringify(body[elKey]) : body[elKey];
      });

    this.props.createUsersEnterprises(body, this.state.modalManageCompany.type === 'manage' ? this.state.selectedCompanyId : null)
      .then((res) => {
        console.log('createUsersEnterprises', res);
        this.props.setModal({isVisible: true, title: 'Company', msg: res.data.msg});
        this.props.setUsersEnterprises();
        this.setState({ modalManageCompany: {...this.state.modalManageCompany, isVisible: false} });
      })
  }

  renderSaveCompany = () => {
    return (
      <Container id="company-save" style={{"textAlign": "center"}}>
        <Row>
          <Col>
          <Button
            variant="success"
            onClick={this.saveCompany}
          >{
            this.state.modalManageCompany.type === 'create'
              ? 'Save Your Company' : 'Update Your Company'
          }</Button>
          </Col>
        </Row>
      </Container>
    )
  }

  renderHistory = () => {
    return (
      <Table striped bordered hover responsive id="historyTable">
        <thead>
          <tr>
            <th>Completed</th>
            <th>Survey Number</th>
            <th>Company</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.history.data.map(el => (
              <tr key={`${el.survey_number}-${el.company_name}-${el.created_at}`}>
                <td style={{textAlign: "center"}}><figure className={`circle ${el.flag_complete === 1 ? "completed" : "notCompleted"}`}></figure></td>
                <td>{el.survey_number}</td>
                <td>{el.company_name}</td>
                <td>{el.created_at}</td>
                <td style={{textAlign: "center"}}>
                  <Button
                    variant="primary"
                    onClick={() => this.props.history.push(`/survey/${this.state.selectedCompanyId}/edit/${el.survey_number}`)}
                    style={{ marginRight: "20px" }}
                  >Edit survey</Button>
                  <Button
                    variant={el.flag_complete === 0 ? "outline-success" : "success"}
                    disabled={el.flag_complete === 0 ? "disabled" : ""}
                    onClick={() => {
                      //chiamato per verificare se esiste un grafico
                      this.props.getGraph({
                        graph_id: 1,
                        enterprise_id: this.state.selectedCompanyId,
                        survey_number: el.survey_number,
                      })
                        .then(() => this.props.history.push(`/graph/${this.state.selectedCompanyId}/survey_number/${el.survey_number}`))
                    }}
                  >View report</Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    )
  }

  HomeContainer = () => {
    return (
      <Container fluid={true}>
        <Row className="justify-content-center">
          <Card>
            <Card.Header>Your Companies</Card.Header>
            <Card.Body>
              <Card.Text style={{textAlign: "center"}}>
                Search for your Companies below, select one of them to create or show your survey.
              </Card.Text>
              <Container>
                <Row className="justify-content-center">
                  <Col xs="auto">
                    <Dropdown>
                      <Dropdown.Toggle variant="success">
                        {
                          !this.state.selectedCompanyId
                            ? 'Select Company'
                            : this.props.home.usersEnterprises.find(el => el.enterprise_id === this.state.selectedCompanyId).company_name
                        }
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {
                          (this.props.home.usersEnterprises || []).map(el => (
                            <Dropdown.Item key={el.enterprise_id} eventKey={el.enterprise_id} onSelect={this.onSelectDropDownItem}>
                              {el.company_name}
                            </Dropdown.Item>
                          ))
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="outline-success"
                      onClick={() => this.onManageCompany('create')}
                    >Create</Button>
                  </Col>
                  {
                    this.state.selectedCompanyId &&
                    <Col xs="auto">
                      <Button
                        variant="outline-primary"
                        onClick={() => this.onManageCompany('manage')}
                      >Manage</Button>
                    </Col>
                  }
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Row>
        {
          this.state.selectedCompanyId &&
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Survey</Card.Title>
                  <Card.Text>Click here to create or edit last survey for the selected company.</Card.Text>
                  <Button
                    variant="success"
                    onClick={() => this.props.history.push(`/survey/${this.state.selectedCompanyId}/create`)}
                  >Create survey</Button>
                  <Button
                    variant="primary"
                    style={{"marginLeft": "5%"}}
                    onClick={() => {
                      this.props.getEnterpriseAnswers(
                        this.state.selectedCompanyId,
                        null,
                      )
                        .then(() => this.props.history.push(`/survey/${this.state.selectedCompanyId}/edit`))
                    }}
                  >Edit last survey</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Show last report</Card.Title>
                  <Card.Text>Click here to show last report for the selected company.</Card.Text>
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      this.props.getGraph({
                        graph_id: 1,
                        enterprise_id: this.state.selectedCompanyId,
                      })
                        .then(() => this.props.history.push(`/graph/${this.state.selectedCompanyId}`))
                    }}
                  >View report</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        }
        {
          this.state.selectedCompanyId &&
          <Row>
            <Card>
              <Card.Body>
                <Card.Title>History</Card.Title>
                <div id="DatePickerContainer">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={this.state.history.startDate}
                    onChange={date => this.onChangeDateHistory(date, "startDate")}
                    selectsStart
                    startDate={this.state.history.startDate}
                    endDate={this.state.history.endDate}
                    placeholderText="Select start date"
                  />
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={this.state.history.endDate}
                    onChange={date => this.onChangeDateHistory(date, "endDate")}
                    selectsEnd
                    startDate={this.state.history.startDate}
                    endDate={this.state.history.endDate}
                    minDate={this.state.history.startDate}
                    placeholderText="Select end date"
                  />
                  <Button
                    variant={!this.state.history.startDate || !this.state.history.endDate ? "outline-secondary" : "secondary"}
                    disabled={!this.state.history.startDate || !this.state.history.endDate ? "disabled" : ""}
                    onClick={() => this.onSearchHistoryEnterprise()}
                  >Search</Button>
                </div>
                {this.renderHistory()}
              </Card.Body>
            </Card>
          </Row>
        }
        <Modal
            size="lg"
            show={this.state.modalManageCompany.isVisible}
            onHide={() => this.setState({ modalManageCompany: {...this.state.modalManageCompany, isVisible: false} })}
            aria-labelledby="manageCompany-modal"
            backdrop={'static'}
            scrollable
          >
            <Modal.Header closeButton>
              <Modal.Title id="modal">
                {this.state.modalManageCompany.type === 'manage' ? "Manage Company" : "Create new Company"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h3>General Enterprise Information</h3>
              { <this.BodyGeneralEnterpriseInformation /> }
              { /*<h3>Enterprise Overview: we want to know well about you</h3> */ }
              { /*<this.BodyCompanyOverview />*/ }
              { <this.renderSaveCompany /> }
            </Modal.Body>
        </Modal>
      </Container>
    )
  }

  render(){    
    return (
      <div id="home" className="pageContainer">
        <this.TopBarContainer />
        <this.HomeContainer />
      </div>

    )
  }

}

Home.propTypes = {

};

export default Home;
