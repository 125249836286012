import loader from './loader';
import modal from './modal';
import preload from './preload';
import login from './login';
import home from './home';
import survey from './survey';
import graph from './graph';

export default {
  loader,
  modal,
  preload,
  login,
  home,
  survey,
  graph,
}
