import React from 'react';
import {
  Modal,
} from 'react-bootstrap';

const ModalComponent = (props) => {
  return props.isVisible && (
    <Modal
        size={props.size || 'sm'}
        centered
        show={props.isVisible}
        onHide={() => props.setModal({isVisible: false})}
        aria-labelledby="login-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.msg || (props.component && props.component())}</Modal.Body>
    </Modal>
  );
}

export default ModalComponent;
