import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more'; //module

import addHistogramModule from 'highcharts/modules/histogram-bellcurve';


import {
  Container,
  Row,
  Col,
  Navbar,
  Button,
} from 'react-bootstrap';
import { validateEmail } from '../../utils';
import '../../stylesheets/graph.scss';

HC_more(Highcharts) //init module
// Apply histogram module
addHistogramModule(Highcharts);

class Graph extends Component {
  constructor(props){
    super(props);

    this.state = {
      graph1Loaded: false,
      graph2Loaded: false,
      graph1: {
        chart: {
            polar: true,
            type: 'area',
            height: 500,
        },

        title: {
            text: 'ENTERPRISE ADEQUACY TO FINANCE AND GREEN GROWTH INNOVATION FINANCEABILITY',
            align: 'center',
        },

        pane: {
            size: '80%'
        },

        xAxis: {
            categories: [],
            tickmarkPlacement: 'on',
            lineWidth: 0,
            labels: {
              padding: 20,
            },
        },

        yAxis: {
            gridLineInterpolation: 'polygon',
            lineWidth: 0,
            min: 0
        },

        tooltip: {
            shared: true,
            pointFormat: '<span style="color:{series.color}">{series.name} <b>{point.y}</b><br/>'
        },

        legend: {
            align: 'center',
            verticalAlign: 'bottom'
        },

        series: [{
            name: 'CHECKED ENTERPRISE: CHART OF THE RESULT',
            data: [],
            pointPlacement: 'on',
            color: '#28a745',
        }, {
            name: 'BENCHMARK: THE OPTIMUS AS REFERENCE',
            data: [],
            pointPlacement: 'on',
            color: '#EF7D00',
        }],

        responsive: {
          rules: [{
              condition: {
                  maxWidth: 500
              },
              chartOptions: {
                  legend: {
                      align: 'center',
                      verticalAlign: 'bottom'
                  },
                  xAxis: {

                        labels: {
                            padding: 20
                        }
                    },
                  pane: {
                      size: '80%'
                  }
              }
          }],
        },
        credits: false,
      },
      graph2: {
        chart: {
            polar: true,
            type: 'area',
            height: 800,
        },

        title: {
            text: 'SUITABILITY AND POSITIONING COMPARED TO THE SINGLE FINANCING INSTRUMENT',
            align: 'center',
        },

        pane: {
            size: '80%'
        },

        xAxis: {
            categories: [],
            tickmarkPlacement: 'on',
            lineWidth: 0,
            labels: {
              padding: 20,
            },
        },

        yAxis: {
            gridLineInterpolation: 'polygon',
            lineWidth: 0,
            min: 0
        },

        tooltip: {
            shared: true,
            pointFormat: '<span style="color:{series.color}">{series.name} <b>{point.y}</b><br/>'
        },

        legend: {
          align: 'center',
          verticalAlign: 'bottom',
        },

        series: [{
            name: 'CHECKED ENTERPRISE: CHART OF THE RESULT',
            data: [],
            pointPlacement: 'on',
            color: '#28a745',
        }, {
            name: 'BENCHMARK: THE OPTIMUS AS REFERENCE',
            data: [],
            pointPlacement: 'on',
            color: '#EF7D00',
        }],

        responsive: {
          rules: [{
              condition: {
                  maxWidth: 500
              },
              chartOptions: {
                legend: {
                  align: 'center',
                  verticalAlign: 'bottom',
                },
                xAxis: {
                  labels: {
                    padding: 20,
                  },
                },
                pane: {
                    size: '80%',
                },
              },
          }],
        },
        credits: false,
      },
      graph3: {
        chart: {
            type: 'histogram',
            height: 500,
        },
        title: {
            text: 'ENTERPRISE ADEQUACY TO FINANCE AND GREEN GROWTH INNOVATION FINANCEABILITY',
            align: 'center',
            style: { color: '#333', }
        },
        xAxis: {
            categories: [],
            tickWidth: 0,
            labels: {
            	style: {
              	color: '#333',
                }
            },
        },
        yAxis: {
          gridLineWidth: .5,
          gridLineDashStyle: 'dash',
          gridLineColor: 'black',
          title: {
            text: '',
            style: {
              color: '#333'
              }
          },
          labels: {
            style: {
              color: '#333',
            }
          }
        },

        tooltip: {
            shared: true,
            pointFormat: '<span style="color:{series.color}">{series.name} <b>{point.y}</b><br/>',
        },
        colors: [
          '#EF7D00',
          '#28a745'
    		],
        plotOptions: {
          column: {
              colorByPoint: true
          }
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom'
        },
        series: [{
        		name: 'BENCHMARK: THE OPTIMUS AS REFERENCE',
            data: [],
            //maxPointWidth: 100,
        }, {
        		name: 'CHECKED ENTERPRISE: CHART OF THE RESULTS',
            data: [],
            //maxPointWidth: 100,
        }],
        responsive: {
          rules: [{
              condition: {
                  maxWidth: 500
              },
              chartOptions: {
                  legend: {
                      align: 'center',
                      verticalAlign: 'bottom'
                  },
                  xAxis: {

                        labels: {
                            padding: 20
                        }
                    },
                  pane: {
                      size: '80%'
                  }
              }
          }],
        },
        credits: false,
      },
    }
    this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount() {
    console.log('Graph did mount');

    // get last result of survey for the specific
    this.props.getGraph({
      graph_id: 1,
      enterprise_id: parseInt(this.props.computedMatch.params.id, 10),
      survey_number: this.props.computedMatch.params.survey_number ? parseInt(this.props.computedMatch.params.survey_number, 10) : undefined,
    });
    this.props.getGraph({
      graph_id: 2,
      enterprise_id: parseInt(this.props.computedMatch.params.id, 10),
      survey_number: this.props.computedMatch.params.survey_number ? parseInt(this.props.computedMatch.params.survey_number, 10) : undefined,
    });
  }

  componentDidUpdate(){
    if(!this.state.graph1Loaded && this.props.graph.graph1){
      this.setState(state => ({
        graph1Loaded: true,
        graph1: {
          ...state.graph1,
          xAxis: {
            ...state.graph1.xAxis,
            categories: this.props.graph.graph1.label,
          },
          series: [{
              name: 'BENCHMARK: THE OPTIMUS AS REFERENCE',
              data: Object.keys(this.props.graph.graph1.benchmark).map((elKey) => parseFloat(this.props.graph.graph1.benchmark[elKey])),
              pointPlacement: 'on',
              color: '#EF7D00',
          }, {
              name: 'CHECKED ENTERPRISE: CHART OF THE RESULT',
              data: Object.keys(this.props.graph.graph1.data).map((elKey) => parseFloat(this.props.graph.graph1.data[elKey])),
              pointPlacement: 'on',
              color: '#28a745',
          }],
        }
      }));
    }
    if(!this.state.graph2Loaded && this.props.graph.graph2){
      this.setState(state => ({
        graph2Loaded: true,
        graph2: {
          ...state.graph2,
          xAxis: {
            ...state.graph2.xAxis,
            categories: this.props.graph.graph2.label,
          },
          series: [{
              name: 'BENCHMARK: THE OPTIMUS AS REFERENCE',
              data: Object.keys(this.props.graph.graph2.benchmark).map((elKey) => parseFloat(this.props.graph.graph2.benchmark[elKey])),
              pointPlacement: 'on',
              color: '#EF7D00',
          }, {
              name: 'CHECKED ENTERPRISE: CHART OF THE RESULT',
              data: Object.keys(this.props.graph.graph2.data).map((elKey) => parseFloat(this.props.graph.graph2.data[elKey])),
              pointPlacement: 'on',
              color: '#28a745',
          }],
        }
      }));
    }
  }

  handleChange = event => {
    const target = event.currentTarget;
    this.setState((state) => ({
      [target.name]: target.value,
    }));
  };

  TopBarContainer = () => {
    return (
      <Navbar bg="light" variant="primary" fixed="top" id="topBar">
        <Navbar.Brand href="#/">
          finMED Tool - View Report
        </Navbar.Brand>
      </Navbar>
    )
  }

  GraphContainer = (props) => {
    const state = this.state;
    let options = state[props.graphKey];

    // filter data by labels
    if(props.filterDataByLabels && Array.isArray(props.filterDataByLabels)){
      options = {
        ...state[props.graphKey],
        title: {
          ...state[props.graphKey].title,
          text: props.title || state[props.graphKey].title.text,
        },
        xAxis: {
          ...state[props.graphKey].xAxis,
          categories: this.props.graph[props.graphKey].label
            .filter(elKeyFilter => props.filterDataByLabels.find(elFind => elFind === elKeyFilter)),
        },
        series: [{
            name: 'BENCHMARK: THE OPTIMUS AS REFERENCE',
            data: Object.keys(this.props.graph[props.graphKey].benchmark)
              .filter(elKeyFilter => props.filterDataByLabels.find(elFind => elFind === elKeyFilter))
              .map((elKey) => parseFloat(this.props.graph[props.graphKey].benchmark[elKey])),
            pointPlacement: 'on',
            color: '#EF7D00',
        }, {
            name: 'CHECKED ENTERPRISE: CHART OF THE RESULT',
            data: Object.keys(this.props.graph[props.graphKey].data)
              .filter(elKeyFilter => props.filterDataByLabels.find(elFind => elFind === elKeyFilter))
              .map((elKey) => parseFloat(this.props.graph[props.graphKey].data[elKey])),
            pointPlacement: 'on',
            color: '#28a745',
        }],
      }
    }

    return (
        <Row style={{marginTop: "20px"}}>
          <Col>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
          </Col>
        </Row>
    );
  }

  GraphContainerHistogram = (props) => {
    const state = this.state;
    let options = state[props.graphKey];

    // filter data by labels
    if(props.filterDataByLabels && Array.isArray(props.filterDataByLabels)){
      options = {
        ...state[props.schema],
        title: {
          ...state[props.schema].title,
          text: props.title || state[props.schema].title.text,
        },
        xAxis: {
          ...state[props.schema].xAxis,
          categories: this.props.graph[props.graphKey].label
            .filter(elKeyFilter => props.filterDataByLabels.find(elFind => elFind === elKeyFilter)),
        },
        series: [{
          ...state[props.schema].series[0],
          data: Object.keys(this.props.graph[props.graphKey].benchmark)
            .filter(elKeyFilter => props.filterDataByLabels.find(elFind => elFind === elKeyFilter))
            .map((elKey) => parseFloat(this.props.graph[props.graphKey].benchmark[elKey])),
        }, {
          ...state[props.schema].series[1],
          data: Object.keys(this.props.graph[props.graphKey].data)
            .filter(elKeyFilter => props.filterDataByLabels.find(elFind => elFind === elKeyFilter))
            .map((elKey) => parseFloat(this.props.graph[props.graphKey].data[elKey])),
        }],
      }
    }

    return (
        <Row style={{marginTop: "20px"}}>
          <Col>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
          </Col>
        </Row>
    );
  }

  highlightsCalc = (hlName, dataKey) => {
    const graph = this.props.graph[dataKey];

    if(!graph || !graph.data)
      return [];

      let calc = [], template = "", answersArrayOfArray = [], answersTemplate = [];
      switch(hlName){
        case 'green':
          // result > benchmark+(benchmark*0.3)
          calc = Object.keys(graph.data).filter(elDatakey => {
            let result = parseFloat(graph.data[elDatakey], 10);
            let benchmark = parseFloat(graph.benchmark[elDatakey], 10);
            return result > benchmark + (benchmark * 0.3);
          });
          // create result descriptions
          if(calc.length !== 0){
            template = graph.highlight_template_spider[0];
            answersArrayOfArray = calc.map(label => graph.data_max_weight[label]);
            answersTemplate = [];
            answersArrayOfArray.forEach(el => {answersTemplate = answersTemplate.concat(el)});
            answersTemplate.sort((a, b) => parseFloat(b.answer_weight) - parseFloat(a.answer_weight));
            answersTemplate = answersTemplate.slice(0, 3);
            answersTemplate.forEach((item, i) => {
              template = template.replace(`[${i}_QUESTION]`, `"${item.question_index}: ${item.question_text}"`);
              template = template.replace(`[${i}_ANSWER]`, `"${item.answer_text}"`);
            });
          }
          return {calc, template};
        case 'enough':
          // result > benchmark+(benchmark*0.1) AND result > benchmark+(benchmark*0.05) AND benchmark*1.1 > result*0.9
          calc = Object.keys(graph.data).filter(elDatakey => {
            let result = parseFloat(graph.data[elDatakey], 10);
            let benchmark = parseFloat(graph.benchmark[elDatakey], 10);
            return result > benchmark + (benchmark * 0.1)
              && result > benchmark + (benchmark * 0.05)
              && benchmark * 1.1 > result * 0.9
          });
          // create result descriptions
          if(calc.length !== 0){
            template = graph.highlight_template_spider[1];
            answersArrayOfArray = calc.map(label => graph.data_max_weight[label]);
            answersTemplate = [];
            answersArrayOfArray.forEach(el => {answersTemplate = answersTemplate.concat(el)});
            answersTemplate.sort((a, b) => parseFloat(b.answer_weight) - parseFloat(a.answer_weight));
            answersTemplate = answersTemplate.slice(0, 3);
            answersTemplate.forEach((item, i) => {
              template = template.replace(`[${i}_QUESTION]`, `"${item.question_index}: ${item.question_text}"`);
              template = template.replace(`[${i}_ANSWER]`, `"${item.answer_text}"`);
            });
          }
          return {calc, template};
        case 'misses':
          // result < benchmark*0.9
          calc = Object.keys(graph.data).filter(elDatakey => {
            let result = parseFloat(graph.data[elDatakey], 10);
            let benchmark = parseFloat(graph.benchmark[elDatakey], 10);
            return result < benchmark * 0.9;
          });
          // create result descriptions
          if(calc.length !== 0){
            template = graph.highlight_template_spider[2];
            answersArrayOfArray = calc.map(label => graph.data_min_weight[label]);
            answersTemplate = [];
            answersArrayOfArray.forEach(el => {answersTemplate = answersTemplate.concat(el)});
            answersTemplate.sort((a, b) => parseFloat(a.answer_weight) - parseFloat(b.answer_weight));            
            answersTemplate = answersTemplate.slice(0, 3);
            answersTemplate.forEach((item, i) => {
              template = template.replace(`[${i}_QUESTION]`, `"${item.question_index}: ${item.question_text}"`);
              template = template.replace(`[${i}_ANSWER]`, `"${item.answer_text}"`);
            });
          }
          return {calc, template};
        default:
          return [];
      }
  }

  render(){
    let graph1 = {
      green: this.highlightsCalc('green', 'graph1'),
      enough: this.highlightsCalc('enough', 'graph1'),
      misses: this.highlightsCalc('misses', 'graph1'),
    };
    let graph2 = {
      green: this.highlightsCalc('green', 'graph2'),
      enough: this.highlightsCalc('enough', 'graph2'),
      misses: this.highlightsCalc('misses', 'graph2'),
    };

    return (
      <div id="graph" className="pageContainer">
        <this.TopBarContainer />
        <Container className="container-marginTop">
          { (this.state.graph1Loaded && this.props.graph.graph1) && <this.GraphContainer graphKey="graph1" /> }
          <Row>
            <Col>
              <Container className="highlights graph1">

                <Row>
                  <Col className="highlights-head highlights-green">WHAT IS GREAT</Col>
                  <Col className="highlights-head highlights-enough">WHAT IS ENOUGH</Col>
                  <Col className="highlights-head highlights-misses">WHAT ENTERPRISE MISSES</Col>
                </Row>
                <Row>
                  <Col>{ (graph1.green.calc || []).map(el => (<p key={el}>{el}</p>)) }</Col>
                  <Col>{ (graph1.enough.calc || []).map(el => (<p key={el}>{el}</p>)) }</Col>
                  <Col>{ (graph1.misses.calc || []).map(el => (<p key={el}>{el}</p>)) }</Col>
                </Row>
                { graph1.green.template && <Row><Col className="highlights-description highlights-green">{graph1.green.template}</Col></Row> }
                { graph1.enough.template && <Row><Col className="highlights-description highlights-enough">{graph1.enough.template}</Col></Row> }
                { graph1.misses.template && <Row><Col className="highlights-description highlights-misses">{graph1.misses.template}</Col></Row> }
              </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              { (this.state.graph2Loaded && this.props.graph.graph2) && <this.GraphContainer graphKey="graph2" /> }
              {
                 (this.state.graph2Loaded && this.props.graph.graph2)
                 && <this.GraphContainer
                      graphKey="graph2"
                      filterDataByLabels={["Bank loan", "Bank overdraft or credit line", "Leasing", "Grants or subsidised bank loan", "Corporate bonds", "Securitised debt", "Covered bonds", "Venture debt", "Private placements", "Crowdfunding (debt)", "Profit participation rights", "Convertible bonds", "Bonds with warrants & Mezzanine finance"]}
                      title="DEBT & QUASI-EQUITY"
                    />
              }
              {
                 (this.state.graph2Loaded && this.props.graph.graph2)
                 && <this.GraphContainer
                      graphKey="graph2"
                      filterDataByLabels={["Business angel", "Crowdfunding (equity)", "Venture capital", "Specialised platforms for public listing of SMEs", "Private placements"]}
                      title="EQUITY"
                    />
              }
              {
                 (this.state.graph2Loaded && this.props.graph.graph2)
                 && <this.GraphContainer
                      graphKey="graph2"
                      filterDataByLabels={["COSME", "Horizon 2020", "Horizon 2020 SMEs Instruments", "Other Ue Funds"]}
                      title="UE financing/support"
                    />
              }
              {
                 (this.state.graph2Loaded && this.props.graph.graph2)
                 && <this.GraphContainerHistogram
                      graphKey="graph2"
                      filterDataByLabels={["Tax credit", "R&D tax credit"]}
                      title="TAX CREDIT"
                      schema="graph3"
                    />
              }
            </Col>
          </Row>
          <Row>
            <Col>
              <Container className="highlights graph2">
                <Row>
                  <Col className="highlights-head highlights-green">WHAT IS WELL SUITABLE</Col>
                  <Col className="highlights-head highlights-enough">WHAT IS ENOUGH SUITABLE</Col>
                  <Col className="highlights-head highlights-misses">WHAT IS NOT SUITABLE</Col>
                </Row>
                <Row>
                  <Col>{ (graph2.green.calc || []).map(el => (<p key={el}>{el}</p>)) }</Col>
                  <Col>{ (graph2.enough.calc || []).map(el => (<p key={el}>{el}</p>)) }</Col>
                  <Col>{ (graph2.misses.calc || []).map(el => (<p key={el}>{el}</p>)) }</Col>
                </Row>
                { graph2.green.template && <Row><Col className="highlights-description highlights-green">{graph2.green.template}</Col></Row> }
                { graph2.enough.template && <Row><Col className="highlights-description highlights-enough">{graph2.enough.template}</Col></Row> }
                { graph2.misses.template && <Row><Col className="highlights-description highlights-misses">{graph2.misses.template}</Col></Row> }
              </Container>
            </Col>
          </Row>

          <Row className="justify-content-center" style={{textAlign: 'center', margin: '20px 0'}}>
            <Col>
              <Button
                variant="primary"
                onClick={() => {
                  this.props.history.push(`/graph/${this.props.computedMatch.params.id}${this.props.computedMatch.params.survey_number ? `/survey_number/${this.props.computedMatch.params.survey_number}` : ""}/full`);
                  window.scrollTo(0,0);
                }}
              >Show full report</Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

}

Graph.propTypes = {

};

export default Graph;
