import request, { catchHandler, generateQueryString, mock} from '../utils/request';
import api from '../config/api';
const env = process.env.NODE_ENV;

export const setLoaderSurvey = (isVisible) => {
  return dispatch => {
    dispatch({
      type: 'LOADER_SET',
      data: isVisible,
      fieldName: 'isVisible',
    })
  }
};

export const getSurveyQuestionAnswer = (params) => {
  return dispatch => {
    return new Promise((resolve) => {
      dispatch({
        type: 'LOADER_SET',
        data: true,
        fieldName: 'isVisible',
      });

      request.get(`${api[env].baseURL}${api[env].apiVersion}${api.services.surveyQuestionAnswer}`, params)
        .then(response => {
          dispatch({
            type: 'LOADER_SET',
            data: false,
            fieldName: 'isVisible',
          });

          dispatch({
            type: 'SURVEY_GET_QUESTION_ANSWER',
            ...response.data,
          });

          resolve(response.data);
        })
        .catch((err) => catchHandler(err, dispatch))
    })
  }
};

export const postSurveyQuestionAnswer = (body) => {
  return dispatch => {
    dispatch({
      type: 'LOADER_SET',
      data: true,
      fieldName: 'isVisible',
    });

    return request.post(`${api[env].baseURL}${api[env].apiVersion}${api.services.usersEnterprisesAnswers}`, body)
      .then((res) => {
        dispatch({
          type: 'LOADER_SET',
          data: false,
          fieldName: 'isVisible',
        });

        return new Promise(resolve => resolve(res));
      })
      .catch((err) => catchHandler(err, dispatch))
  }
};

export const getEnterpriseAnswers = (id, survey_number) => {
  return dispatch => {

    return new Promise((resolve, reject) => {
      request.get(`${api[env].baseURL}${api[env].apiVersion}${api.services.usersEnterprisesAnswers}${survey_number ? `/${survey_number}` : "" }?enterprise_id=${id}`)
        .then(resolve)
        .catch((err) => {
          catchHandler(err, dispatch);
          reject();
        })
    });
  }
};
