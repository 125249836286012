import { push } from 'connected-react-router';
import request, { catchHandler, generateQueryString, mock } from '../utils/request';
import api from '../config/api';
const env = process.env.NODE_ENV;

export const resetGraph = () => ({
  type: 'GRAPH_RESET_DATA',
})

export const getGraph = (params) => {
  return dispatch => {
    return new Promise(resolve => {
      dispatch({
        type: 'LOADER_SET',
        data: true,
        fieldName: 'isVisible',
      });
      request.get(`${api[env].baseURL}${api[env].apiVersion}${api.services.graph}${params.graph_id}${params.survey_number ? `/${params.survey_number}` : ""}${generateQueryString(params)}`)
        .then(response => {
          setTimeout(() =>
            dispatch({
              type: 'LOADER_SET',
              data: false,
              fieldName: 'isVisible',
            })
          , 4000)

          dispatch({
            type: 'GRAPH_GET_DATA',
            graph: {
              key: `graph${params.graph_id}`,
              data: {...response.data},
            },
          });

          resolve();
        })
        .catch((err) => catchHandler(err, dispatch))
    })
  }
};

export const setGraphNotes = (params) => {
  return dispatch => {
    return new Promise(resolve => {
      dispatch({
        type: 'LOADER_SET',
        data: true,
        fieldName: 'isVisible',
      });

      request.post(`${api[env].baseURL}${api[env].apiVersion}${api.services.graphNotes}`, params)
        .then(response => {
          dispatch({
            type: 'LOADER_SET',
            data: false,
            fieldName: 'isVisible',
          });

          resolve(response);
        })
        .catch((err) => catchHandler(err, dispatch))
    })
  }
};
