export default {
  production: {
    baseURL: `${window.location.protocol}//${window.location.hostname}`,
    apiVersion: '/api',
  },
  development: {
    baseURL: `https://www.finmedtool.eu`,
    apiVersion: '/api',
  },
  services: {
    login: '/login',
    usersEnterprises: '/users/enterprises',
    historyEnterprise: '/users/enterprises/answers/history',
    surveyQuestionAnswer: '/survey/question/answer',
    usersEnterprisesAnswers: '/users/enterprises/answers',
    graph: '/spider',
    graphNotes: '/users/enterprises/answers/note',
    passwordEmail: '/password/email',
    passwordReset: '/password/reset',
  }
}
