import initialState from '../store/initState/survey';

export default (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case 'SURVEY_GET_QUESTION_ANSWER':
      newState = {...state};      
      newState.questionAnswer = action.data.survey;
      return newState;
    default:
      return state;
  }
};
