import request, { catchHandler, generateQueryString } from '../utils/request';
import api from '../config/api';
const env = process.env.NODE_ENV;

export const preloadField = (fieldName, params = {}) => {
  return dispatch => {
    request.get(`${api[env].baseURL}${api[env].apiVersion}${api.services[fieldName]}${generateQueryString(params)}`)
      .then(response => {
        dispatch({
          type: 'PRELOAD_SET',
          data: response.data,
          fieldName,
        })
      })
      .catch(catchHandler)
  }
};
