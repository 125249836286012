import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Row,
  Col,
  Navbar,
  Dropdown,
  Button,
  Card,
  Accordion,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import moment from 'moment';
import { elementToPDF } from '../../utils';
import '../../stylesheets/survey.scss';

const AREA_COLORS = [
  '#335EFF',
  '#02b9c1',
  '#10EF6B',
  '#EF105E',
  '#EFA810',
  '#adc101',
  '#5A10EF',
];

class Survey extends Component {
  constructor(props){
    super(props);

    this.state = {
      userAnswers: {},
      areaAnswersCount: {},
      currentAreaId: 1,
    }

    this.handleChange = this.handleChange.bind(this);
    this.saveSurvey = this.saveSurvey.bind(this);
  }

  async componentDidMount() {
    console.log('Survey did mount', this.props.computedMatch.params);

    // init setting, call survey question answer when data is null
    let surveyQuestionAnswer;
    if(this.props.login.authentication.user_id){
      surveyQuestionAnswer = await this.props.getSurveyQuestionAnswer();
    }

    if(this.props.computedMatch.params.type === 'edit'){
      const enterpriseAnswersLoaded = await this.props.getEnterpriseAnswers(
        this.props.computedMatch.params.id,
        this.props.computedMatch.params.survey_number || null
      )
        .catch(err => this.props.history.push('/'));

      if(enterpriseAnswersLoaded && surveyQuestionAnswer){
        const statePreload = {};
        enterpriseAnswersLoaded.data.data.forEach(elId => {
          surveyQuestionAnswer.data.survey.forEach(area => {
            area.questions.forEach(q => {
              if(q.answers.find(ans => ans.survey_question_answer_id === elId)){
                if(statePreload[q.question_id]){
                  statePreload[q.question_id].push(elId);
                }else{
                  statePreload[q.question_id] = [elId];
                }
              }
            })
          })
        })

        this.setState({
          userAnswers: {
            ...statePreload,
          }
        });
      }
    }
  }

  TopBarContainer = () => {
    return (
      <Navbar bg="light" variant="primary" fixed="top" id="topBar">
        <Navbar.Brand href="#/">
          finMED Tool - {this.props.computedMatch.params.type === 'edit' ? 'Edit' : 'Create' } Survey
        </Navbar.Brand>
      </Navbar>
    )
  }

  handleChange = event => {
    const target = event.currentTarget;
    const addUniqArray = (target, state) => {
      if(target.type === 'checkbox'){
        let surveyState =  state[target.name] || [];
        // if not exist item then add it else remove from state
        return !surveyState.find(el => el === parseInt(target.id, 10))
          ? [...surveyState, parseInt(target.id, 10)]
          : surveyState.filter(el => el !== parseInt(target.id, 10))
      }else return [parseInt(target.id, 10)];
    };

    this.setState((state) => ({
      ...state,
      userAnswers: {
        ...state.userAnswers,
        [target.name]: addUniqArray(target, state.userAnswers),
      }
    }));
  }

  navArea = (nav) => {
    this.setState(state => {
      const currentAreaId = state.currentAreaId + nav;
      return {
        currentAreaId,
      };
    }, () => window.scrollTo(0,0))
  }

  saveSurvey = () => {
    const questionsCounter = (this.props.survey.questionAnswer || [])
      .reduce((acc, currVal) => acc + currVal.questions.length, 0);
    const questionsState = Object.keys(this.state.userAnswers)
      .filter(el => el.length !== 0);
    const flagComplete = questionsState.length === questionsCounter
      ? 1 : 0

    const body = {
      survey_question_answer_id: questionsState.reduce((acc, currKey) => [...acc, ...this.state.userAnswers[currKey]], []),
      enterprise_id: parseInt(this.props.computedMatch.params.id, 10),
      survey_number: this.props.computedMatch.params.survey_number ? parseInt(this.props.computedMatch.params.survey_number, 10) : undefined,
      flag_complete: flagComplete,
    };

    this.props.postSurveyQuestionAnswer(body)
      .then(res => {
        this.props.setModal({isVisible: true, title: 'Survey', msg: res.data.msg || 'All answers saved'});
        if(flagComplete === 1)
          this.props.history.push(`/graph/${this.props.computedMatch.params.id}`);
      })
  }

  onExportSurvey = () => {
    this.props.setLoaderSurvey(true);
    let enterpriseId = parseInt(this.props.computedMatch.params.id, 10);
    
    // open all cards and show all areas
    this.setState({ currentAreaId: null, }, () => {
      [...document.querySelectorAll(".card .collapse")].forEach(el => el.classList.add("show"));
      elementToPDF(
        "#survey-area",
        `${this.props.home.usersEnterprises
            .find(el => el.enterprise_id === enterpriseId).company_name}-${moment().format('YYYYMMDD')}-finmed-survey.pdf`
      )
        .then(() => {
          // restore section
          this.setState({currentAreaId: 1});
          [...document.querySelectorAll(".card .collapse")].forEach(el => el.classList.remove("show"));
          this.props.setLoaderSurvey(false);
        })
    });
  }

  renderSurvey = (el, elKey) => {
    const statusArea = (color) => {
      const questionsIds = el.questions.map(el => el.question_id);
      let userAnswersCount = 0;
      Object.keys(this.state.userAnswers).forEach((uaKey) => {
        if(questionsIds.find(q => q === parseInt(uaKey, 10) && this.state.userAnswers[uaKey].length !== 0))
          userAnswersCount++;
      });

      if(userAnswersCount === questionsIds.length)
        return (<span className="status-survey-area" style={{color: '#28a745'}}>Completed</span>)
      else if(userAnswersCount > 0)
        return (<span className="status-survey-area" style={{color}}>In Progress</span>)
      else
        return (<span className="status-survey-area" style={{color}}>Not Completed</span>)
    }
    const elem = (
      <Card key={elKey} className="survey-area">
        <Card.Text style={{"textAlign": "right", "marginBottom": "0px"}}>
          { statusArea(AREA_COLORS[elKey]) }
        </Card.Text>
        <Card.Header style={{"backgroundColor": AREA_COLORS[elKey]}}>{ el.description }</Card.Header>
        <Card.Body>
          <Accordion defaultActiveKey="0">
            {
              el.questions.map((elQ, elQKey) => (
                <Card key={elQKey}>
                  <Accordion.Toggle as={Card.Header} eventKey={elQKey}>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip id="survey-tooltip">{elQ.question_desc}</Tooltip>}
                    >
                      <div>
                        <figure
                          className={`circle survey-question-fleg ${this.state.userAnswers[elQ.question_id] && this.state.userAnswers[elQ.question_id].length !== 0 ? 'selected' : ''}`}
                        ></figure>
                        { `${elQ.question_index} - ${elQ.question_text}` }
                      </div>
                    </OverlayTrigger>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={elQKey}>
                    <Card.Body>
                    {
                      elQ.answers.map(elA => (
                        <Form.Check
                          key={elA.survey_question_answer_id}
                          id={elA.survey_question_answer_id}
                          label={elA.answer_text}
                          name={elQ.question_id}
                          onChange={this.handleChange}
                          type={elQ.question_type === 'R' ? 'radio' : 'checkbox'}
                          checked={
                            this.state.userAnswers[elQ.question_id]
                              ? this.state.userAnswers[elQ.question_id].find(el => el === elA.survey_question_answer_id) !== undefined : false
                          }
                        />
                      ))
                    }
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))
            }
          </Accordion>
        </Card.Body>
      </Card>
    );

    return elem;
  }

  renderSaveSurvey = () => {
    const currentAreaId = this.state.currentAreaId - 1;
    const questionAnswerLen = this.props.survey.questionAnswer
      ? this.props.survey.questionAnswer.length : 0;

    return (
      <Container id="survey-save">
        <Row className="justify-content-center">
          <Col>
            { currentAreaId > 0
              && <Button
                  style={{margin: "0px 5px"}}
                  variant="outline-primary"
                  onClick={() => this.navArea(-1)}
                 >Prev</Button>
            }
            {
              currentAreaId < questionAnswerLen-1
              && <Button
                  style={{margin: "0px 5px"}}
                  variant="primary"
                  onClick={() => this.navArea(1)}
                 >Next</Button>
            }
            <Button
              style={{margin: "0px 20px"}}
              variant="success"
              onClick={this.saveSurvey}
            >Save Your Survey</Button>
            {
              currentAreaId === questionAnswerLen-1
              && <Button
                    style={{margin: "0px 5px"}}
                    variant="outline-success"
                    onClick={() => this.onExportSurvey()}
                 >Export Sarvey</Button>
            }
          </Col>
        </Row>
      </Container>
    )
  }

  SurveyContainer = () => {
    const currentAreaId = typeof this.state.currentAreaId === "number"
      ? this.state.currentAreaId - 1 : this.state.currentAreaId;

    return (
      <Container fluid={true}>
        <Row>
          <Card>
            <Card.Body>
              <div id="survey-area">
                { currentAreaId === null && (this.props.survey.questionAnswer || []).map((el, elI) => this.renderSurvey(el, elI)) }
                { typeof currentAreaId === "number" && this.props.survey.questionAnswer && this.renderSurvey(this.props.survey.questionAnswer[currentAreaId], currentAreaId) }
              </div>
              { this.renderSaveSurvey() }
            </Card.Body>
          </Card>
        </Row>
      </Container>
    )
  }

  render(){
    return (
      <div id="survey" className="pageContainer">
        <this.TopBarContainer />
        <this.SurveyContainer />
      </div>

    )
  }

}

Survey.propTypes = {

};

export default Survey;
