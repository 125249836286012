import { Component } from 'react';
import PropTypes from 'prop-types';

class Authentication extends Component {
  state = {

  }

  static getDerivedStateFromProps(props) {

    // check user authentication
    if(document.location.hash.indexOf('#/login') === -1 && !props.data.user_id){
      props.history.push('/login');
    }

    return {};
  }

  render(){    
    return null;
  }

}

Authentication.propTypes = {

};

export default Authentication;
