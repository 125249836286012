import initialState from '../store/initState/login';

export default (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case 'LOGIN_SET_LOADER':
      newState = {...state};
      newState.loader = action.data;
      return newState;
    case 'LOGIN_SET_AUTH':
      newState = {...state};
      newState.authentication = action.data;
      return newState;
    default:
      return state;
  }
};
