import { push } from 'connected-react-router';
import request, { catchHandler, generateQueryString, mock } from '../utils/request';
import api from '../config/api';
const env = process.env.NODE_ENV;

export const setAuthentication = (params) => {
  return dispatch => {
    return new Promise(resolve => {
      dispatch({
        type: 'LOADER_SET',
        data: true,
        fieldName: 'isVisible',
      });

      request.post(`${api[env].baseURL}${api[env].apiVersion}${api.services.login}`, params)
        .then(response => {
          resolve();

          dispatch({
            type: 'LOADER_SET',
            data: false,
            fieldName: 'isVisible',
          });

          dispatch({
            type: 'LOGIN_SET_AUTH',
            data: {
              ...response.data,
            },
          });

          dispatch(push('/'));
        })
        .catch((err) => catchHandler(err, dispatch))
    });
  }
};

export const setLoader = (data) => ({
  type: 'LOGIN_SET_LOADER',
  data,
});

export const passwordEmail = (email) => {
  return dispatch => {
    return request.post(`${api[env].baseURL}${api[env].apiVersion}${api.services.passwordEmail}`, {
      email,
    })
    .catch((err) => catchHandler(err, dispatch))
  }
}

export const passwordReset = (params = {}) => {
  return dispatch => {
    return request.post(`${api[env].baseURL}${api[env].apiVersion}${api.services.passwordReset}`, params)
    .catch((err) => catchHandler(err, dispatch))
  }
}
