import axios from 'axios';
const defaultTimeout = 30000;

export default axios.create({
  timeout: defaultTimeout,
  withCredentials: true,
  headers: {
    'Cache-Control': 'no-cache', // fix no-cache for IE
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
  }
});

export const generateQueryString = (params) => {
  let queryString = '';
  Object.keys(params).forEach((elKey, elIterator) => {
    const elValue = params[elKey];
    switch(typeof elValue){
      case 'object':
        if(Array.isArray(elValue) && elValue.length !== 0)
          queryString = `${queryString}&${elKey}=${encodeURIComponent(elValue.join(','))}`;
        break;
      default:
        if(elValue)
          queryString = `${queryString}&${elKey}=${encodeURIComponent(elValue)}`;
        break;
    }
  });

  return queryString !== ''
    ? `?${queryString}`
    : '';
};

export const blobDownload = (props) => {
  const { fileName, data } = props;

  if(fileName && data){
    if(window.navigator.msSaveOrOpenBlob){
      const blobObject = new Blob([data]);
      window.navigator.msSaveOrOpenBlob(blobObject, fileName);
    }else{
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
  }
}

export const catchHandler = (err, dispatch) => {
  dispatch({
    type: 'LOADER_SET',
    data: false,
    fieldName: 'isVisible',
  });

  if(err.response){
    console.log(err.response);
    switch(err.response.status){
      case 400: // Bad Request
        dispatch({
          type: 'MODAL_SET',
          data: {
            isVisible: true,
            title: 'Error',
            msg: err.response.data.msg || "Generic Error",
          },
          fieldName: 'isVisible',
        });
        break;
      case 401: // Unauthorized used by session server auth
        dispatch({
          type: 'MODAL_SET',
          data: {
            isVisible: true,
            title: 'Error',
            msg: 'Access unauthorized',
          },
          fieldName: 'isVisible',
        });
        break;
      case 403: // Forbidden used by user profile permissions
        dispatch({
          type: 'MODAL_SET',
          data: {
            isVisible: true,
            title: 'Error',
            msg: err.response.data.msg || "Generic Error",
          },
          fieldName: 'isVisible',
        });
        break;
      case 404: // Not Found
        dispatch({
          type: 'MODAL_SET',
          data: {
            isVisible: true,
            title: 'Warning',
            msg: err.response.data.msg || "Generic Error",
          },
          fieldName: 'isVisible',
        });
        break;
      case 500: // Internal Server Error
        dispatch({
          type: 'MODAL_SET',
          data: {
            isVisible: true,
            title: 'Error',
            msg: err.response.data.message || "Generic Error",
          },
          fieldName: 'isVisible',
        });
        break;
      default:
        break;
    }
  }else{
    console.log(`Generic error occurred: ${err.message}`);
  }

  return Promise.reject(err);
};
