import initialState from '../store/initState/graph';

export default (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case 'GRAPH_SET_LOADER':
      newState = {...state};
      newState.loader = action.data;
      return newState;
    case 'GRAPH_GET_DATA':
      newState = {...state};
      newState[action.graph.key] = action.graph.data;
      return newState;
    case 'GRAPH_RESET_DATA':
      newState = {
        ...state,
        graph1: null,
        graph2: null,
      };      
      return newState;
    default:
      return state;
  }
};
