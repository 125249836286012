import initialState from '../store/initState/home';

export default (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case 'HOME_SET_USERS_ENTERPRISES':
      newState = {...state};
      newState.usersEnterprises = action.data.data;
      return newState;
    default:
      return state;
  }
};
